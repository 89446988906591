.FlexDashboard {
  display: flex;
  flex-wrap: wrap;
  //   justify-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  gap: 30px;
  //   width: 65%;
  //   background-color: #2f1d58;
  //   height: 80vh;
  overflow: auto;
  flex-direction: row;
}

.FlexDashboardCol {
  width: 400px;
  min-width: 360px;
  //   height: 285px;

  // justify-content: center;
  // align-items: center;

  //   background-color: rgba(219, 255, 160, 0.5);
  //   box-shadow: 0px 0px 20px 0px #4c577d05;
  position: relative;
}

.ExpiredCol {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;

  .card {
    width: 400px;
    min-width: 360px;
    height: auto;
    border-radius: 12px;
    background-color: white;
    padding: 27px 30px;
    box-shadow: 0px 0px 20px 0px #4c577d05;
    // animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    // animation: slide-fwd-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    // animation: slide-fwd-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

.card_border {
  position: absolute;
  top: 0;
  left: -30px;
  width: 400px;
  min-width: 360px;
  border-top: 1px solid #eff2f5;
}

@keyframes slide-top {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slide-fwd-center {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translateZ(160px);
  }
}

@keyframes slide-fwd-top {
  0% {
    transform: translateZ(0) translateY(0);
  }
  100% {
    transform: translateZ(160px) translateY(-10px);
  }
}

@media only screen and (max-width: 1276px) {
  .FlexDashboard {
    justify-content: center;
  }
}

@media only screen and (max-width: 550px) {
  .card {
    width: 100% !important;
    // margin: 27px 5px !important;
  }
}
