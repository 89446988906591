.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  background-color: rgb(192, 27, 178) !important; 
}
.fc .fc-col-header-cell-cushion { 
  padding-top: 5px;
  padding-bottom: 5px; 
  background-color: #61dafb;
}

.fc-theme-standard td, .fc-theme-standard th{
  background-color: burlywood;
  width: 150px !important;
  height: 100px;
} */

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 12px 4px !important;
  /* background-color: rgb(192, 27, 178) !important; */
  border: none !important;
}

/* , .fc-button.fc-button-primary */
.fc-prev-button,
.fc-next-button {
  color: #80808f;
  background: rgb(255, 255, 255);
  background-image: none;
  border-color: #ebedf2;
  width: 40px !important;
  height: 33px !important;
  font-size: 10px;
  border-radius: 0px !important;
}

.fc-button.fc-button-primary {
  color: #7e8299 !important;
  background: rgb(255, 255, 255) !important;
  background-image: none;
  border-color: #ebedf2 !important;
  width: 136px;
  height: 34px;
  font-size: 12px !important;
  border-radius: 12px;
}
