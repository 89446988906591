body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter" "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  font-family: "Poppins";
  /* font-family: "Inter"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    color-scheme: light !important;
    background-color: #f2f3f7 !important;
  }
}

.f-center {
  @apply flex justify-center items-center;
}

.h-text {
  @apply font-semibold text-[36px] text-primary1;
}

.h2-text {
  @apply font-semibold text-[20px] text-primary5;
}

.h3-text {
  @apply font-semibold text-[16x] text-primary5;
}

.p-text {
  @apply text-[16px] text-primary3 leading-6;
}

.p2-text {
  @apply text-[14px] font-medium text-primary4 leading-6;
}

.p3-text {
  @apply text-[14px] font-bold  leading-6;
}

.btn-filled {
  @apply w-full h-[44px];
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.x-shadow {
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.x-input {
  @apply bg-white h-[44px] text-primary3  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  /* background-color: white !important; */
}

.x-doc-input {
  @apply bg-primary8 h-[54px] text-primary3  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
  /* border: 1px solid #d0d5dd !important; */
  box-shadow: 0px 1px 2px 0px #1018280d !important;
}

.x-navItem {
  /* rounded-tl-[20px] rounded-tr-[20px]  */
  @apply flex flex-col  w-[142px] h-[60px] mb-[-16px] font-medium rounded-tl-[20px] rounded-tr-[20px] text-center  justify-center  text-white   md:p-0 md:dark:text-blue-500;
}

@media only screen and (max-width: 767px) {
  .x-navItem {
    /* rounded-tl-[20px] rounded-tr-[20px]  */
    @apply w-[142px] h-[60px] mb-[0px] text-left rounded-[20px];
    background-color: transparent !important;
  }
}

.table tr {
  @apply text-primary7;
  border-bottom-color: #eff2f5;
  font-size: 16px;

  &:hover {
    background-color: #eff2f5;
  }
}

.table thead tr {
  background: #f5f8fa;
  text-transform: uppercase;
  color: #b5b5c3;
  /* font-family: sans-serif; */
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
}

.steps-vertical .step {
  padding-bottom: 48px !important;
}

.steps-vertical .step:before {
  height: 170%;
  width: 1px;
  background-color: #dddddd;
}

.steps .step:after {
  width: 40px;
  height: 40px;
  border-radius: 9px;
  color: #4fc3f7;
  background-color: #f1faff;
}

.steps .step-primary + .step-primary:before,
.steps .step-primary:after {
  background-color: #4fc3f7;
  color: white;
}

/* .calender_date {
  @apply px-2 py-2 my-3 text-white;

  border-left: 3px solid;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.calender_date:hover {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.calender_expired {

  @apply text-xred;
  border-color: #f1416c;
}

.calender_expiring {
  @apply text-xyellow;
} */

.completed_task {
  text-decoration: line-through;
  color: "#b5b5c3";
  opacity: 0.3;
}

.eye-icon {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 40px;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
}

.custom-select::after {
  content: "▼"; /* Custom arrow */
  position: absolute;
  right: 15px;
  top: 45px;
  /* transform: translateY(-50%); */
  pointer-events: none; /* Ensure click doesn't affect arrow */
  font-size: 12px;
  color: #333;
}
