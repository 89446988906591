.FlexContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  /* background-color: #2f1d58; */
  height: 100vh;
  overflow: auto;
  flex-direction: row;
}

.FlexCard1 {
  // flex-basis: 50%;
  width: 50%;
  height: 100%;

  // background-image: url("https://app.xpiree.com/assets/Images/login.svg");
  background-image: url("../../assets/login_side_img.svg");
  background-size: cover; /* Ensures the background image covers the entire div */
  background-position: center; /* Centers the background image */
}

.FlexCard2 {
  width: 50%;
  height: 100%;
  background: #f2f3f7;

  .login_container {
    width: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .FlexCard1 {
    width: 100%;
  }
  .FlexCard2 {
    width: 100%;
  }
}
