.info_board {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  gap: 21px;
  overflow: auto;
  flex-direction: row;

  .info_board_left {
    width: 70%;
    min-height: 224px;
    // width: 828px;
    // height: 224px;
    border-radius: 12px;
    padding: 30px;
    background-color: white;

    .imgDiv {
      background: #e1f0ff;
      border-radius: 12px;
    }
  }

  .info_board_right {
    // width: 25%;
    width: 360px;
    height: 224px;
    border-radius: 12px;
    padding: 22px;
    background-color: white;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 1250px) {
  //   .info_board {
  //     background-color: teal;
  //   }
  .info_board_left {
    width: 100%;
    height: auto;
    justify-content: center;
  }
  .info_board_right {
    // width: 100%;
    width: 70% !important;
  }
}

@media only screen and (max-width: 550px) {
  //   .info_board {
  //     background-color: teal;
  //   }
  .info_board_left {
    width: 90% !important;
  }

  .info_board_right {
    width: 90% !important;
  }
}
