.FlexFolders {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 30px;
  //   width: 65%;
  //   background-color: #2f1d58;
  //   height: 80vh;
  overflow: auto;
  flex-direction: row;
}

.FolderCard {
  width: 200px;
  height: 190px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px #4c577d05;
  position: relative;

  .menu_icon {
    position: absolute;
    top: 17px;
    right: 20px;
  }
}

@media only screen and (max-width: 925px) {
  .FlexFolders {
    justify-content: center;
  }
}
