.FlexDoc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //   align-items: center;
  align-content: center;
  width: 100%;
  gap: 21px;
  overflow: auto;
  flex-direction: row;

  .doc_left {
    width: 360px;
    height: 70vh;
    // min-height: 224px;
    // width: 828px;
    // height: 224px;
    border-radius: 12px;
    padding: 45px 30px;
  }

  .doc_right {
    // width: 25%;
    width: 70%;
    min-height: 224px;
    // height: 70vh;
    border-radius: 12px;
    padding: 47px 100px;
    background-color: white;
    display: flex;
    // align-items: center;
    // text-align: center;
  }
}

.flex_reminder {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 90%;
  // justify-content: center;
  //   align-items: center;
  // align-content: center;
  .reminder_card {
    width: 154px;
    height: 46px;
    border: 1px solid #a7a8bb;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1250px) {
  .doc_left {
    width: 70% !important;
    height: auto !important;
    justify-content: center;
  }
  .doc_right {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .doc_left {
    width: 90% !important;
    height: auto !important;
    justify-content: center;
  }
  .doc_right {
    width: 90% !important;
    padding: 47px 20px !important;
  }
}
