.card {
  background-color: rgb(255, 255, 255);
  // width: 545px;
  width: 49%;
  //   max-width: 620px;
  // width: 620px;

  //   height: 360px;
  border-radius: 12px;
  padding: 30px;
}

.reminder_badge {
  color: #00a3ff;
  border: 1px solid #00a3ff;
  padding: 6px 12px;
  border-radius: 20px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
}

.sharer_badge {
  display: flex;
  gap: 15px;
  align-items: center;
  color: #3f4254;
  border: 1px solid #e3e3e3;
  padding: 6px 12px;
  border-radius: 20px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 1250px) {
  .card {
    width: 100% !important;
    height: auto !important;
    // justify-content: center;
  }
}
